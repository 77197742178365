import { LabelsObject, SharedStateModel, TaxonomyVariable } from '../shared.model';
import { StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { difference } from 'lodash-es';
import { map } from 'rxjs/operators';
import { TaxonomyVariablesApiService } from '../../../../generated/apps-api';
import { distinct } from '../../utils/arrays.utils';
import { replaceKnownVariablesFromLabels } from './replace-variables.utils';

export class ResolveTaxonomyVariablesUseCases {

  private expectedVariables = [
    '${filteredItems}',
    '${instrumentType}',
    '${currentScenarioIndex}',
    '${countScenarios}',
    '${contextName}',
    '${currentView}',
    '${contextName}',
    '${x}',
    '${totalSteps}',
    '${contextName}',
    '${savedContextsNumber}',
    '${first}',
    '${last}',
    '${resultType}',
    '${instrument}',
    '${service}',
    '${name}',
    '${service}',
    '${instrumentsNumber}',
    '${first}',
    '${first}',
    '${second}',
    '${month}',
    '${day}',
    '${year}',
    '${contextName}'];

  constructor(private taxonomyVariablesApiService: TaxonomyVariablesApiService) {
  }


  public getNonExistingVariablesInStore(context: StateContext<SharedStateModel>, labels: LabelsObject) {
    const taxonomyVariables = this.getAllTaxonomyVariables(labels);
    return this.loadTaxonomyVariables(context, taxonomyVariables);
  }


  public resolveVariablesInLabels(taxonomyVariables: TaxonomyVariable[], labels: LabelsObject): {
    labelObject: LabelsObject,
    changedLabelKeys: string[]
  } {
    return replaceKnownVariablesFromLabels(labels, taxonomyVariables);
  }


  private loadTaxonomyVariables(context: StateContext<SharedStateModel>, taxonomyVariablesInLabels: string[]): Observable<TaxonomyVariable[]> {
    const missingTaxonomyVariablesInStore = difference(taxonomyVariablesInLabels, Object.keys(context.getState()?.taxonomyVariables || []));
    if (taxonomyVariablesInLabels?.length === 0 || difference(taxonomyVariablesInLabels, this.expectedVariables)?.length == 0) {
      return of(context.getState()?.taxonomyVariables);
    }
    if (missingTaxonomyVariablesInStore.length > 0) {
      return this.taxonomyVariablesApiService.resolveTaxonomyVariables({ variablesIdentifiers: missingTaxonomyVariablesInStore.filter(distinct) }).pipe(map(response => {
        const variables: TaxonomyVariable[] = context.getState().taxonomyVariables;
        response.variableResponseList?.forEach(v => variables.push({
          identifier: v.identifier,
          value: v.value,
          options: undefined
        }));

        return variables;
      }));
    } else {
      return of(context.getState()?.taxonomyVariables);
    }

  }


  private getAllTaxonomyVariables(labels: LabelsObject): string[] {
    const results = JSON.stringify(labels).matchAll(/\${(.*?)}/g);
    return [...results].map(r => r?.[0]);
  }


}
