import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { ClientOnboardingAppContextApiService, ClientOnboardingEvaluationApiService, ClientOnboardingLabelsApiService } from 'src/generated/apps-api';
import { ClientIdentificationResponse, Question, Request, RequirementGroup, Role, RuleSetInfo } from 'src/generated/apps-api/model/models';
import { v4 as uuid } from 'uuid';
import { CompleteLoading, LoadRuleSetLabels, SetErrors, SetStaticLabels, SetTestMode } from '../../shared/state/shared.action';
import { LabelsObject, RuleSet } from '../../shared/state/shared.model';
import {
  LoadClientClassificationLabels,
  LoadContext,
  LoadOtherRuleSetsLabels,
  ProcessCurrentRuleSetEvaluation,
  RollbackCurrentRuleSetEvaluation,
  SetContextJurisdiction,
  SetCurrentRuleSet,
  SetStaticResult,
  StartAgain,
  ToggleRolesAccordions,
  UpdateRole
} from './client-onboarding.action';
import { DEFAULT_STATE } from './client-onboarding.defaultState';
import { ClientOnboardingStateModel, ExtendedRole, RuleSetContext } from './client-onboarding.model';

@State<ClientOnboardingStateModel>({
  name: 'clientOnboarding',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ClientOnboardingState {
  constructor(
    private contextService: ClientOnboardingAppContextApiService,
    private evalService: ClientOnboardingEvaluationApiService,
    private labelsService: ClientOnboardingLabelsApiService,
    private router: Router,
    private store: Store
  ) {}

  @Selector()
  public static jurisdiction(state: ClientOnboardingStateModel): string {
    return state.jurisdiction;
  }

  @Selector()
  public static requirementGroups(state: ClientOnboardingStateModel): RequirementGroup[] {
    return state.requirementGroups;
  }

  @Selector()
  public static roles(state: ClientOnboardingStateModel): ExtendedRole[] {
    return state.roles;
  }

  @Selector()
  public static expandedRoles(state: ClientOnboardingStateModel): { [key: string]: boolean } {
    return state.expandedRoles;
  }

  @Selector()
  public static jurisdictions(state: ClientOnboardingStateModel): string[] {
    return state.availableJurisdictions;
  }

  @Selector()
  public static ruleSetContexts(state: ClientOnboardingStateModel): { [key: string]: RuleSetContext } {
    return state.ruleSetContexts;
  }

  @Selector()
  public static currentRuleSet(state: ClientOnboardingStateModel): RuleSet {
    return state.currentRuleSet;
  }

  @Action(LoadContext)
  public loadContext(context: StateContext<ClientOnboardingStateModel>) {
    return this.contextService.getAppContext().pipe(
      tap(result => {
        if (result.configurationException) {
          this.store.dispatch(new SetErrors({ errors: { ...result.configurationException, stepsToGoBack: 2 } })).subscribe(() => this.router.navigate(['/errors']).then());
        } else {
          context.patchState({
            availableJurisdictions: result.jurisdictions
          });
        }
        this.store.dispatch(new SetStaticLabels({ labels: result.staticLabels })).subscribe(() => this.store.dispatch(new CompleteLoading()));
        this.store.dispatch(new SetTestMode({ testMode: result.testMode }));
      })
    );
  }

  @Action(SetContextJurisdiction)
  public setContextJurisdiction(context: StateContext<ClientOnboardingStateModel>, { payload }: SetContextJurisdiction) {
    return context.patchState({
      jurisdiction: payload.jurisdiction
    });
  }

  @Action(SetCurrentRuleSet)
  public setCurrentRuleSet(context: StateContext<ClientOnboardingStateModel>, { payload }: SetCurrentRuleSet) {
    const ruleSetContexts = cloneDeep(context.getState().ruleSetContexts);
    if (!ruleSetContexts[payload.ruleSet]) {
      ruleSetContexts[payload.ruleSet] = {
        questions: [],
        ruleSetInfo: {
          ruleSetType: RuleSet[payload.ruleSet]
        },
        request: {
          attributes: this.calculateInitialAttributes(payload.ruleSet, context)
        }
      };
    }
    return context.patchState({
      currentRuleSet: payload.ruleSet,
      ruleSetContexts
    });
  }

  @Action(RollbackCurrentRuleSetEvaluation)
  public rollbackCurrentRuleSetEvaluation(context: StateContext<ClientOnboardingStateModel>, { payload }: RollbackCurrentRuleSetEvaluation) {
    const lastAttributeName = payload?.lastAttribute?.name;
    const currentRuleSet = context.getState().currentRuleSet;
    const ruleSetContexts = cloneDeep(context.getState().ruleSetContexts);
    const ruleSetContext = ruleSetContexts[currentRuleSet];
    if (currentRuleSet === RuleSet.CLIENT_CLASSIFICATION) {
      Object.keys(ruleSetContexts)
        .filter(rs => RuleSet.CLIENT_CLASSIFICATION !== rs)
        .forEach(rs => delete ruleSetContexts[rs]);
    }
    if (ruleSetContext?.request) {
      if (payload?.lastAttribute) {
        const attributeIndex = ruleSetContext.request.attributes?.findIndex(a => a.name === lastAttributeName);
        if (attributeIndex > -1) {
          ruleSetContext.request.attributes = ruleSetContext.request.attributes?.slice(0, attributeIndex);
          const questionIndex = ruleSetContext.questions.findIndex(q => q.property === lastAttributeName);
          ruleSetContext.questions = ruleSetContext.questions.slice(0, questionIndex + 1);
        }
      } else {
        ruleSetContext.request.attributes = this.calculateInitialAttributes(currentRuleSet, context);
        ruleSetContext.questions = [];
      }
      return context.patchState({
        ruleSetContexts,
        requirementGroups: []
      });
    } else {
      return of(true);
    }
  }

  @Action(StartAgain)
  public startAgain(context: StateContext<ClientOnboardingStateModel>) {
    context.patchState({
      ...DEFAULT_STATE
    });
    return this.store.dispatch(new SetErrors());
  }

  @Action(ProcessCurrentRuleSetEvaluation)
  public processCurrentRuleSetEvaluation(context: StateContext<ClientOnboardingStateModel>, { payload }: ProcessCurrentRuleSetEvaluation) {
    const ruleSetContexts = cloneDeep(context.getState().ruleSetContexts);
    const currentRuleSet = context.getState().currentRuleSet;
    const ruleSetContext = ruleSetContexts[currentRuleSet];
    if (payload?.newAttribute) {
      ruleSetContext.request.attributes.push(cloneDeep(payload.newAttribute));
    }
    const request = cloneDeep(ruleSetContext.request);
    request.jurisdiction = context.getState().jurisdiction;
    return this.invokeEvalEndpoint(request, ruleSetContext, context).pipe(
      tap(result => {
        if (result.ruleSetInfo) ruleSetContext.ruleSetInfo = result.ruleSetInfo;
        if (result.question) {
          ruleSetContext.questions = ruleSetContext.questions.filter(q => q.property !== result.question.property).concat(result.question);
        }
        context.patchState({
          ruleSetContexts
        });
      })
    );
  }

  @Action(UpdateRole)
  public updateRole(context: StateContext<ClientOnboardingStateModel>, { payload }: UpdateRole): ClientOnboardingStateModel {
    const roles = cloneDeep(context.getState().roles);
    const innerRoles = this.findParentRole(payload.role, undefined, roles)?.roles || roles;

    const roleIndex = innerRoles?.findIndex(r => r.id === payload.role.id);
    innerRoles[roleIndex] = cloneDeep(payload.role);

    context.patchState({
      roles
    });
    return context.getState();
  }

  @Action(ToggleRolesAccordions)
  public toggleRoleAccordion(context: StateContext<ClientOnboardingStateModel>, { payload }: ToggleRolesAccordions): ClientOnboardingStateModel {
    let expandedRoles = cloneDeep(context.getState().expandedRoles);
    if (!expandedRoles) {
      expandedRoles = {};
    }
    payload.rolesIds.forEach(roleId => (expandedRoles[roleId] = !expandedRoles[roleId]));

    context.patchState({
      expandedRoles
    });
    return context.getState();
  }

  private findParentRole(role: ExtendedRole, parentRole: ExtendedRole, roles: ExtendedRole[]): ExtendedRole {
    for (const innerRole of roles ?? []) {
      if (innerRole.id === role.id) {
        return parentRole;
      }
      const found = this.findParentRole(role, innerRole, innerRole.roles);
      if (found) {
        return found;
      }
    }
    return undefined;
  }

  private calculateInitialAttributes(ruleSet: RuleSet, context: StateContext<ClientOnboardingStateModel>) {
    if (ruleSet === RuleSet.CLIENT_IDENTIFICATION_NATURAL_PERSON || ruleSet === RuleSet.CLIENT_IDENTIFICATION_NON_NATURAL_PERSON) {
      return cloneDeep(context.getState().ruleSetContexts[RuleSet.CLIENT_CLASSIFICATION].request.attributes);
    }
    return [];
  }

  private invokeEvalEndpoint(request: Request, ruleSet: RuleSetContext, context: StateContext<ClientOnboardingStateModel>): Observable<{ question?: Question; ruleSetInfo?: RuleSetInfo }> {
    let response: Observable<{ ruleSetInfo?: RuleSetInfo; question?: Question }>;
    switch (ruleSet.ruleSetInfo.ruleSetType) {
      case 'CLIENT_IDENTIFICATION_NATURAL_PERSON':
        response = this.processClientIdentificationEvaluation(this.evalService.getClientIdentificationNaturalPersonEval(request), context);
        break;
      case 'CLIENT_IDENTIFICATION_NON_NATURAL_PERSON':
        response = this.processClientIdentificationEvaluation(this.evalService.getClientIdentificationNonNaturalPersonEval(request), context);
        break;
      case 'CLIENT_CLASSIFICATION':
        response = this.processClientClassificationEvaluation(request, context, ruleSet);
        break;
      default:
        response = of(undefined);
        break;
    }
    return response;
  }

  private processClientClassificationEvaluation(request: Request, context: StateContext<ClientOnboardingStateModel>, ruleSet: RuleSetContext) {
    return this.evalService.getClientClassificationEval(request).pipe(
      tap(response => {
        if (response.roles) {
          context.patchState({
            roles: response.roles?.map(role => this.buildExtendedRole(role)),
            requirementGroups: response.requirementsGroup || []
          });
          if (response.nextRuleSet) {
            ruleSet.nextRuleSet = RuleSet[response.nextRuleSet];
          }
        } else {
          context.patchState({
            roles: undefined
          });
        }
      })
    );
  }

  private buildExtendedRole(role: Role) {
    return {
      ...role,
      id: uuid(),
      roles: undefined,
      requirementGroups: []
    } as ExtendedRole;
  }

  private processClientIdentificationEvaluation(response: Observable<ClientIdentificationResponse>, context: StateContext<ClientOnboardingStateModel>) {
    return response.pipe(
      tap((resp: ClientIdentificationResponse) => {
        if (resp.requirementsGroup) {
          context.patchState({
            requirementGroups: resp.requirementsGroup
          });
        }
      })
    );
  }

  @Action(LoadClientClassificationLabels)
  public loadClientClassificationLabels(context: StateContext<ClientOnboardingStateModel>, { payload }: LoadClientClassificationLabels) {
    const jurisdiction = payload?.jurisdiction || context.getState().jurisdiction;
    return this.dispatchLoadLabels(jurisdiction, RuleSet.CLIENT_CLASSIFICATION, () => this.labelsService.getClientClassificationLabels(jurisdiction));
  }

  @Action(LoadOtherRuleSetsLabels)
  public loadOtherRuleSetsLabels(context: StateContext<ClientOnboardingStateModel>, { payload }: LoadOtherRuleSetsLabels) {
    const jurisdiction = payload?.jurisdiction || context.getState().jurisdiction;
    this.dispatchLoadLabels(jurisdiction, RuleSet.CLIENT_IDENTIFICATION_NON_NATURAL_PERSON, () => this.labelsService.getClientIdentificationNonNaturalPersonLabels(jurisdiction)).subscribe();
    this.dispatchLoadLabels(jurisdiction, RuleSet.CLIENT_IDENTIFICATION_NATURAL_PERSON, () => this.labelsService.getClientIdentificationNaturalPersonLabels(jurisdiction)).subscribe();
    this.dispatchLoadLabels(jurisdiction, RuleSet.OPERATIONAL_ACTIVITY, () => this.labelsService.getOperationalActivityLabels(jurisdiction)).subscribe();
  }

  @Action(SetStaticResult)
  public setStaticResult(context: StateContext<ClientOnboardingStateModel>, { payload }: SetStaticResult) {
    context.patchState({
      ...payload.state
    });
    return this.store.dispatch(new CompleteLoading());
  }

  private dispatchLoadLabels(jurisdiction: string, ruleSetType: string, fetchLabelsFunction: () => Observable<LabelsObject>) {
    return fetchLabelsFunction().pipe(
      first(),
      switchMap(result => {
        return this.store.dispatch(new LoadRuleSetLabels({ jurisdiction, ruleSetType, labels: result }));
      })
    );
  }
}
